import { useCallback, useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { useLocation } from 'react-router-dom';
import usePlayAudio from './usePlayAudio';
import { useDispatch } from 'store';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { chatApi, useLazyGetMessagesByIdQuery } from 'store/rtk-query/chatApi';

function useChatWebSocket() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { audio } = usePlayAudio();
  const { data = {}, isLoading } = useGetUserInfoQuery();
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userStatus, setUserStatus] = useState({});
  const [getMessagesById] = useLazyGetMessagesByIdQuery();

  const userId = useRef(selectedUser?.uuid || null);
  const heartbeatInterval = useRef(null);

  useEffect(() => {
    userId.current = selectedUser?.uuid;
  }, [selectedUser?.uuid]);

  const handleMessage = useCallback(
    async (payload) => {
      const receivedMessage = JSON.parse(payload.body);
      setMessages((prev) => [...prev, receivedMessage]);
      if (location.pathname?.includes('/chat') && userId.current === receivedMessage?.sender?.uuid) {
        await getMessagesById(receivedMessage?.id);
      } else {
        dispatch(
          chatApi.util.updateQueryData('messagesCount', undefined, (prev) => {
            prev.newMessageCount += 1;
          })
        );
        dispatch(
          chatApi.util.updateQueryData('recentChatUserList', undefined, (prev) => {
            const currentUserData = prev;
            const findSenderIndex = currentUserData?.findIndex((x) => x?.uuid === receivedMessage?.sender?.uuid);
            if (findSenderIndex !== -1) {
              currentUserData[findSenderIndex].newMessageCount += 1;
              const recentChat = currentUserData[findSenderIndex];
              currentUserData.splice(findSenderIndex, 1);
              currentUserData.unshift(recentChat);
            } else {
              const senderData = {
                uuid: receivedMessage.sender.uuid,
                firstName: receivedMessage.sender.firstName,
                lastName: receivedMessage.sender.lastName,
                profileImage: receivedMessage.sender.profileImage,
                newMessageCount: 1
              };
              currentUserData.unshift(senderData);
            }
          })
        );
      }
      try {
        audio.play();
      } catch (error) {
        console.error('error', error);
        /* empty */
      }
    },
    [audio, dispatch, getMessagesById, location.pathname]
  );

  useEffect(() => {
    if (!data?.uuid) return;

    const newSock = new SockJS(`${process.env.REACT_APP_BASE_API}/ws`);
    const socketClient = over(newSock);

    socketClient.connect({ uuid: data?.uuid }, () => {
      socketClient.subscribe(`/users/${data?.uuid}/queue/messages`, handleMessage);
      socketClient.subscribe(`/queue/status`, (message) => {
        const status = JSON.parse(message.body);
        setUserStatus(status);
      });

      // Start sending heartbeat messages every 30 seconds
      heartbeatInterval.current = setInterval(() => {
        if (socketClient.connected) {
          socketClient.send('/app/heartbeat', {}, JSON.stringify({ uuid: data?.uuid }));
        }
      }, 30000);
    });

    setSocket(socketClient);

    return () => {
      if (socketClient && socketClient.connected) {
        socketClient.disconnect();
      }
      clearInterval(heartbeatInterval.current);
    };
  }, [handleMessage, data?.uuid]);

  return [socket, messages, setMessages, data, selectedUser, setSelectedUser, isLoading, userStatus];
}

export default useChatWebSocket;
