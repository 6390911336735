import { useNavigate } from 'react-router-dom';
import { handleLogout } from '@utils/helpers/logoutHelper';
import { useLogoutMutation } from '@store/rtk-query/authApi';
import { useDispatch } from 'react-redux';
import useLocalStorage from './useLocalStorage';
import paths from 'utils/constants/paths';

const useLogout = () => {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const [, setJwtToken] = useLocalStorage('');

  const handleUserLogout = (event) => {
    event.stopPropagation();
    logout();
    navigate(paths.Login.url, { replace: true });
    setJwtToken('');
    handleLogout(dispatch);
  };

  return handleUserLogout;
};

export default useLogout;
