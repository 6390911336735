import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import globalConstant from './constants/globalConstant';
import { handleLogout } from './helpers/logoutHelper.js';

export const BASE_URL = process.env.REACT_APP_BASE_API;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = JSON.parse(localStorage.getItem(globalConstant.JWT_TOKEN));
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    window.location.replace('/login');
    handleLogout();
  }

  return result;
};

export const RtkBaseQuery = retry(baseQueryWithAuth, {
  maxRetries: globalConstant.DEFAULT_MAX_RETRIES_CONTROLLER_LEVEL
});
export const customBackOff = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });
};
