import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import rootReducer from './rootReducer';
import { userAPI } from './rtk-query/userApi';
import { organizationApi } from './rtk-query/organizationApi';
import { initiativesApi } from './rtk-query/initiativesApi';
import { usersListApi } from './rtk-query/usersListApi';
import { inviteeApi } from './rtk-query/inviteeApi';
import { keywordsApi } from './rtk-query/keywordsApi';
import { newsApi } from './rtk-query/newsApi';
import { networkApi } from './rtk-query/networkApi';
import { utilityApi } from './rtk-query/utilityApi';
import { chatApi } from './rtk-query/chatApi';
import { authApi } from './rtk-query/authApi';
import { licenseTransactionApi } from './rtk-query/licenseTransactionApi';
import { tourApi } from './rtk-query/tourApi';
import { notificationsApi } from './rtk-query/notificationsApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    userAPI.middleware,
    organizationApi.middleware,
    initiativesApi.middleware,
    licenseTransactionApi.middleware,
    usersListApi.middleware,
    inviteeApi.middleware,
    keywordsApi.middleware,
    newsApi.middleware,
    networkApi.middleware,
    utilityApi.middleware,
    chatApi.middleware,
    authApi.middleware,
    tourApi.middleware,
    notificationsApi.middleware
  ]
});

const { dispatch } = store;
const useDispatch = () => useAppDispatch();
const useSelector = () => useAppSelector;

export { store, dispatch, useSelector, useDispatch };
