import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state) => {
      state.isAuthenticated = true;
    },
    logoutAction: (state) => {
      state.isAuthenticated = false;
    }
  }
});

export const { loginAction, logoutAction } = authSlice.actions;
export const authReducer = authSlice;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
