import { combineReducers } from '@reduxjs/toolkit';
import { menuReducer } from './reducers/menuReducer';
import { searchKeywordReducer } from './reducers/searchKeywordsReducer';
import { tourGuideReducer } from './reducers/tourGuideReducer';
import { authReducer } from './reducers/authSlice';
import { userAPI } from './rtk-query/userApi';
import { organizationApi } from './rtk-query/organizationApi';
import { initiativesApi } from './rtk-query/initiativesApi';
import { usersListApi } from './rtk-query/usersListApi';
import { inviteeApi } from './rtk-query/inviteeApi';
import { keywordsApi } from './rtk-query/keywordsApi';
import { newsApi } from './rtk-query/newsApi';
import { networkApi } from './rtk-query/networkApi';
import { utilityApi } from './rtk-query/utilityApi';
import { chatApi } from './rtk-query/chatApi';
import { authApi } from './rtk-query/authApi';
import { licenseTransactionApi } from './rtk-query/licenseTransactionApi';
import { tourApi } from './rtk-query/tourApi';
import { notificationsApi } from './rtk-query/notificationsApi';

const appReducer = combineReducers({
  [userAPI.reducerPath]: userAPI.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [initiativesApi.reducerPath]: initiativesApi.reducer,
  [licenseTransactionApi.reducerPath]: licenseTransactionApi.reducer,
  [menuReducer.name]: menuReducer.reducer,
  [searchKeywordReducer.name]: searchKeywordReducer.reducer,
  [tourGuideReducer.name]: tourGuideReducer.reducer,
  [usersListApi.reducerPath]: usersListApi.reducer,
  [inviteeApi.reducerPath]: inviteeApi.reducer,
  [keywordsApi.reducerPath]: keywordsApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [networkApi.reducerPath]: networkApi.reducer,
  [utilityApi.reducerPath]: utilityApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [tourApi.reducerPath]: tourApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [authReducer.name]: authReducer.reducer
});

// Root reducer with RESET_STORE action
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined; // Reset state
  }
  return appReducer(state, action);
};

export default rootReducer;
