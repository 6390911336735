import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useLocalStorage from '@hooks/useLocalStorage';
import globalConstant from '@constants/globalConstant';
import paths from 'utils/constants/paths';

const PublicRoute = () => {
  const [jwtTokenValue] = useLocalStorage(globalConstant.JWT_TOKEN, '');
  let location = useLocation();

  if (jwtTokenValue) {
    return <Navigate to={location.state?.from || paths.Root.url} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

PublicRoute.propTypes = {
  userId: PropTypes.string
};

export default PublicRoute;

PublicRoute.propTypes = {
  children: PropTypes.node,
  redirect: PropTypes.string
};
